<template>
  <div t-id="b2b-basket-wrapper">
    <b2x-drawer
      v-if="scopeId"
      t-id="b2b-basket-wrapper__drawer"
      :is-open="showDrawer"
      drawer-id="basket"
      position="right"
      width="md"
      :heading="t('basketHeading')"
      @drawerClose="showDrawer = false"
    >
      <div v-show="basketPending" t-id="b2b-basket-wrapper__spinner-container">
        <transition name="fade-zoom">
          <div class="b2b-basket-wrapper__basket-loader">
            <b2x-spinner size="large" t-id="b2b-basket-wrapper__spinner" />
          </div>
        </transition>
      </div>
      <div
        class="b2b-basket-wrapper__basket-container"
        t-id="b2b-basket-wrapper__basket-container"
        v-show="!basketPending"
      >
        <template v-if="basketsAreEmpty">
          <telia-heading
            t-id="b2b-basket-wrapper__empty-baskets-container"
            tag="h2"
            variant="title-200"
          >
            {{ t("basketEmpty") }}
          </telia-heading>
          <img src="./assets/empty_basket_img.svg" class="absolute-center" alt="" />
        </template>
        <template v-else>
          <telia-heading
            tag="h2"
            variant="title-300"
            t-id="b2b-product-cart-organisation"
            class="b2b-basket-wrapper__organisation-header"
          >
            {{ organisation?.name }}
          </telia-heading>
          <B2bProductCartCe
            v-if="!amandaBasketIsEmpty"
            t-id="b2b-basket-wrapper__product-cart"
            class="b2b-basket-wrapper__basket"
            :basket="amandaBasket"
            :organisations="organisations"
            :is-blacklisted="isBlacklisted"
            :tscid="tscid"
            :scope-id="scopeId"
            :is-replicated-mfa="isReplicatedMfa"
            @remove-item-begin="removingBasketItem = true"
            @remove-item-end="basketItemRemoved"
          />
          <b2b-basket
            t-id="b2b-basket-wrapper__b2b-basket"
            class="b2b-basket-wrapper__basket"
            :basket.prop="spockBasket"
            :tscid="tscid"
            :scope-id="scopeId"
            @update-basket="updateSpockBasket"
          />
        </template>
      </div>
    </b2x-drawer>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ComputedRef,
  onBeforeUnmount,
  onMounted,
  ref,
  Ref,
  watch,
  WritableComputedRef,
} from "vue";
import { translateMixin, translateSetup } from "./locale";
import { currentLanguage } from "@telia/b2b-i18n";
import { getBasketSpock } from "../services/basket-management-service";
import { getBasketAmanda, isBlacklistedForFraud } from "../services/product-order-service";
import { getOrganisationsForScope } from "../services/scope-information-service";
import type { GetBasketCall, Lang, Unpromise } from "../services/typings/types";
import { BasketDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { BasketUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import "@telia/b2x-spinner";
import "@telia/b2b-basket";
import { Basket, Organisation, BasketSource } from "./components/typings/types";
import B2bProductCartCe from "./components/b2b-product-cart/product-cart.ce.vue";
import { trackViewCart } from "./components/utils/ga4Helper";

const documentBody = document.body;
onMounted(async () => {
  documentBody.addEventListener("update:spockbasket", getSpockBasket);
  documentBody.addEventListener("update:amandabasket", getAmandaBasket);
  window.addEventListener("load", addBasketButton);
  addBasketButton();
  scopeId.value = await getScopeIdOrThrow();
  organisations.value = await getOrganisations();
  getSpockBasket();
  getAmandaBasket();
  await fetchBlacklistedForFraud(props.tscid);
});

onBeforeUnmount(() => {
  documentBody.removeEventListener("update:spockbasket", getSpockBasket);
  documentBody.removeEventListener("update:amandabasket", getAmandaBasket);
  window.removeEventListener("load", addBasketButton);
  removeBasketButton();
});

translateSetup(["mybusiness"]);
const t = translateMixin.methods.t;

const emit = defineEmits<{
  (e: "update-drawer-state", isOpen: boolean): void;
  (e: "get-basket-error", hasError: boolean, source: BasketSource): void;
  (e: "basket-count", count: number): void;
  (e: "amanda-basket", amandaBasket: BasketUI): void;
  (e: "spock-basket", spockBasket: BasketDTO): void;
}>();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  tscid: {
    type: String,
    required: true,
  },
  isReplicatedMfa: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const scopeId = ref("");
const spockBasket = ref<BasketDTO>({});
const amandaBasket = ref<Basket>({} as Basket);
const fetchingSpockBasket = ref<boolean>(false);
const fetchingAmandaBasket = ref<boolean>(false);
const removingBasketItem = ref<boolean>(false);
const isBlacklisted = ref<boolean>(false);
const organisations = ref<Organisation[]>([]);

watch(
  () => props.tscid,
  (newTscid, oldTscid) => {
    if (newTscid !== oldTscid) {
      getAmandaBasket();
      getSpockBasket();
    }
  }
);

const basketPending: ComputedRef<boolean> = computed<boolean>(() => {
  return fetchingSpockBasket.value || fetchingAmandaBasket.value || removingBasketItem.value;
});
const numberOfItemsInBasket: ComputedRef<number> = computed<number>(() => {
  return (
    (amandaBasket.value?.lines?.length || 0) +
    (amandaBasket.value?.hardwareLines?.length || 0) +
    (amandaBasket.value?.processLines?.length || 0) +
    (amandaBasket.value?.accessoryLines?.length || 0) +
    (amandaBasket.value?.emnLines?.length || 0) +
    (spockBasket.value?.broadbandItems?.length || 0)
  );
});
const amandaBasketIsEmpty: ComputedRef<boolean> = computed<boolean>(() => {
  return (
    (amandaBasket.value?.lines?.length || 0) +
      (amandaBasket.value?.hardwareLines?.length || 0) +
      (amandaBasket.value?.processLines?.length || 0) +
      (amandaBasket.value?.emnLines?.length || 0) +
      (amandaBasket.value?.accessoryLines?.length || 0) ===
    0
  );
});

const showDrawer: WritableComputedRef<boolean> = computed<boolean>({
  get() {
    return props.isOpen;
  },
  set(val) {
    emit("update-drawer-state", val);
  },
});
const isTscidValid: ComputedRef<boolean> = computed<boolean>(() => {
  return organisations.value?.some((org) => org.tscid === props.tscid) || false;
});

const basketsAreEmpty: ComputedRef<boolean> = computed(() => {
  return numberOfItemsInBasket.value === 0;
});

const organisation: ComputedRef<Organisation | undefined> = computed(() =>
  organisations.value.find((organisation) => organisation.tscid === props.tscid)
);

async function getOrganisations() {
  try {
    return getOrganisationsForScope(scopeId.value);
  } catch {
    return [];
  }
}

async function fetchBlacklistedForFraud(tscid: string) {
  if (!tscid || !isTscidValid) return;
  const response = await isBlacklistedForFraud(scopeId.value, tscid);
  if (tscid === props.tscid) {
    isBlacklisted.value = response;
  }
}

async function getBasket<T extends GetBasketCall, U extends Unpromise<ReturnType<T>>>(
  getBasketCall: T,
  scopeId: string,
  tscid: string,
  lang: Lang,
  basketRef: Ref<U>,
  loadingRef: Ref<boolean>,
  source: BasketSource
) {
  try {
    emit("get-basket-error", false, source);
    loadingRef.value = true;
    const response = await getBasketCall(scopeId, tscid, lang);
    if (noBasketResponse(response)) return;
    if (tscid !== props.tscid) return;
    basketRef.value = response;
    updateBasketCount();
  } catch {
    if (tscid !== props.tscid) return;
    basketRef.value = {} as U;
    emit("get-basket-error", true, source);
    updateBasketCount();
  } finally {
    if (tscid === props.tscid) loadingRef.value = false;
  }
}

async function getSpockBasket() {
  await getBasket(
    getBasketSpock,
    scopeId.value,
    props.tscid,
    currentLanguage().toUpperCase() as Lang,
    spockBasket,
    fetchingSpockBasket,
    BasketSource.SPOCK
  );
  emit("spock-basket", spockBasket.value);
}

async function getAmandaBasket() {
  await getBasket(
    getBasketAmanda,
    scopeId.value,
    props.tscid,
    currentLanguage().toUpperCase() as Lang,
    amandaBasket as Ref<BasketUI>,
    fetchingAmandaBasket,
    BasketSource.AMANDA
  );
  emit("amanda-basket", amandaBasket.value);
}

function updateSpockBasket({ detail: basket }) {
  spockBasket.value = basket;
  showDrawer.value = numberOfItemsInBasket.value > 0;
  updateBasketCount();
  emit("spock-basket", spockBasket.value);
}

function noBasketResponse(response) {
  return response?.status === 204;
}

async function basketItemRemoved() {
  removingBasketItem.value = false;
  await getAmandaBasket();
  showDrawer.value = numberOfItemsInBasket.value > 0;
}

function updateBasketCount() {
  emit("basket-count", numberOfItemsInBasket.value);
  window.dispatchEvent(
    new CustomEvent("b2b-navigation:basket-count", {
      detail: { basketCount: numberOfItemsInBasket.value },
      bubbles: true,
      composed: true,
    })
  );
}

function removeBasketClickListeners() {
  window.removeEventListener("b2b-navigation:basket-click", openBasket);
}

function addBasketButton() {
  removeBasketClickListeners();
  window.dispatchEvent(
    new CustomEvent("b2b-navigation:show-basket-nav-item", {
      detail: { showBasket: true },
      bubbles: true,
      composed: true,
    })
  );
  addBasketClickListeners();
}

function addBasketClickListeners() {
  window.addEventListener("b2b-navigation:basket-click", openBasket);
}

function removeBasketButton() {
  window.dispatchEvent(
    new CustomEvent("b2b-navigation:show-basket-nav-item", {
      detail: { showBasket: false },
      bubbles: true,
      composed: true,
    })
  );
  removeBasketClickListeners();
}

function openBasket() {
  trackViewCart(amandaBasket.value);
  showDrawer.value = true;
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.b2b-basket-wrapper {
  &__organisation-header {
    margin-bottom: $telia-spacing-16;
  }

  &__basket-loader {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  &__basket-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__basket {
    flex: 1 1;

    & + & {
      margin-top: 2.4rem;
    }
  }
}

.absolute-center {
  margin: auto;
  position: absolute;
  width: 40%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
